import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {fromLonLat} from 'ol/proj';


@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor() {
  }

  getCurrentCoordinates(): Observable<Coordinates> {
    return new Observable(obs => {
      navigator.geolocation.getCurrentPosition(
        success => {
          obs.next(success.coords);
          obs.complete();
        },
        error => {
          obs.error(error);
        }
      );
    });
  }

  getOSMCoordinates() {
    return this.getCurrentCoordinates()
      .pipe(map(coords => fromLonLat([coords.longitude, coords.latitude])));
  }
}
