import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SettingsComponent} from './settings/settings.component';
import {FavouriteDeparturesComponent} from './departures/favourite/favourite-departures.component';
import {AddRouteComponent} from './settings/add-stop/add-route.component';
import {NearestDeparturesComponent} from './departures/nearest/nearest-departures.component';
import {MapComponent} from './map/map.component';
import {DirectionComponent} from './departures/direction/direction.component';
import {AlertsComponent} from './alerts/alerts.component';

const routes: Routes = [
  {path: 'settings', component: SettingsComponent},
  {path: 'direction', component: DirectionComponent},
  {path: '', component: FavouriteDeparturesComponent},
  {path: 'nearest', component: NearestDeparturesComponent},
  {path: 'settings/add-route', component: AddRouteComponent},
  {path: 'map', component: MapComponent},
  {path: 'alerts', component: AlertsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
