<div class="container">
  <div class="col-12 pl-0">
    <label for="timepicker">Wyjdź po:</label>
  </div>
  <div class="row">
    <div class="col-8">
      <timepicker (click)="showMap = false;
                            reloadDirection()" [(ngModel)]="time" [minuteStep]="1" [showMeridian]="false"
                  id="timepicker"></timepicker>
    </div>
    <div class="col-1 road-mode mr-2">
      <div (click)="changeMode(DirectionMode.TRANSIT)"
           [class]="{'active': mode === DirectionMode.TRANSIT}"
           [inlineSVG]="'assets/icons/public_transport.svg'">
      </div>
    </div>
    <div class="col-1 road-mode">
      <div (click)="changeMode(DirectionMode.BICYCLING)"
           [class]="{'active': mode === DirectionMode.BICYCLING}"
           [inlineSVG]="'assets/icons/bike.svg'">
      </div>
    </div>
    <div class="col-1 road-mode ml-2">
      <div (click)="changeMode(DirectionMode.WALKING)"
           [class]="{'active': mode === DirectionMode.WALKING}"
           [inlineSVG]="'assets/icons/walk.svg'">
      </div>
    </div>
  </div>
  <img *ngIf="isLoading" class="w-100" src="assets/icons/loading.gif"/>
  <div *ngIf="shouldShowSearchBar()" class="row">
    <div class="col-12">
      <label for="place">Wyszukaj miejsce docelowe</label>
    </div>
  </div>
  <div *ngIf="shouldShowSearchBar()" class="row">
    <div class="col-12">
      <input (selectItem)="findDirection($event.item)" [inputFormatter]="formatter" [ngbTypeahead]="search"
             [resultFormatter]="formatter"
             class="form-control"
             id="place" type="text"/>
    </div>
  </div>

  <div *ngIf="leg" class="row">
    <div class="col-2">
      <img (click)="reset()" class="h-75" src="assets/icons/left-arrow.svg"/>
    </div>
    <div class="col-6"></div>
    <div *ngIf="leg && leg.steps.length" class="col-2 pr-0 mr-0">
      <div (click)="showMap = false" [class]="{'active': !showMap}" [inlineSVG]="'assets/icons/steps.svg'">
      </div>
    </div>
    <div *ngIf="leg && leg.steps.length" class="col-2 ml-0 pl-0">
      <div (click)="showMap = true" [class]="{'active': showMap}" [inlineSVG]="'assets/icons/map.svg'">
      </div>
    </div>
  </div>

  <app-not-found *ngIf="leg && !leg.steps.length" description="Nie znaleziono trasy">
  </app-not-found>
  <app-direction-steps *ngIf="!showMap" [leg]="leg" [mode]="mode" [place]="place"></app-direction-steps>
  <app-direction-map *ngIf="showMap && !isLoading" [leg]="leg"></app-direction-map>
</div>

