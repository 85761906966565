<ng-template #train_trip_content let-modal>
  <div [ngClass]="{ 'modal-header' : true,'pb-1': goBackAction}" class="modal-header mb-0 pb-1">
    <button (click)="modal.dismiss(); goBackAction();" *ngIf="goBackAction" aria-label="Close"
            class="close align-text-left ml-0 pl-0"
            type="button">
      <img class="close-icon" src="assets/icons/left-arrow.svg"/>
    </button>
    <h6 class="modal-title text-center" id="modal-basic-title">{{title}}</h6>
    <button (click)="modal.dismiss()" aria-label="Close"
            class="close ml-0" type="button">
      <img class="close-icon" src="assets/icons/cancel.svg"/>
    </button>
  </div>
  <div class="modal-body pl-0 pr-0">
    <div class="container pr-0">
      <ng-container *ngFor="let leg of legs">
        <div [ngClass]="{'font-weight-bold': leg.isInFuture}" class="leg row">
          <div *ngIf="!leg.isInFuture" class="col-1">
            <img src="assets/icons/stop_grey.svg" width="15em">
          </div>
          <div *ngIf="leg.isInFuture" class="col-1">
            <img src="assets/icons/stop_black.svg" width="15em">
          </div>
          <div class="col-3 ml-0 mr-0 pr-0">
            {{leg.timeToArrive }}
          </div>
          <div class="col-2">
            {{leg.time }}
          </div>
          <div class="col-6 mr-0 pt-0 ml-0 pr-0">
            {{leg.stopName}}
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</ng-template>



