import {Component} from '@angular/core';
import {map, mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Stop, StopClientService} from '../../client/stop-client-service';
import * as _ from 'lodash';
import {Router} from '@angular/router';
import {FavouriteRoute, FavouriteRoutesService} from '../../client/favourite-routes.service';
import {RouteClientService} from '../../client/route-client.service';

@Component({
  templateUrl: './add-route.component.html',
  styleUrls: ['./add-route.component.less']
})
export class AddRouteComponent {

  public stopFromGroup: StopGroup;
  public stopToGroup: StopGroup;

  constructor(private stopClientService: StopClientService,
              private favouriteRoutesService: FavouriteRoutesService,
              private routeClientService: RouteClientService,
              private router: Router) {
  }

  formatter(stopGroup: StopGroup): string {
    return stopGroup.format();
  }

  search = (text: Observable<string>): Observable<StopGroup[]> =>
    text
      .pipe(mergeMap(t => this.stopClientService.findStopsByQuery(t)))
      .pipe(map(groups => _.chain(groups)
        .groupBy(s => s.shortName)
        .values()
        .map(g => new StopGroup(g))
        .value()
      ));

  addRoute() {
    const route = new FavouriteRoute();
    const toStops = this.stopToGroup.stops;
    const fromStops = this.stopFromGroup.stops;
    route.toStops = toStops;
    route.fromStops = fromStops;
    route.patterns = this.routeClientService.getPatternsOfRoute(fromStops, toStops);
    this.favouriteRoutesService.addRoute(route);
    this.router.navigate(['settings']);
  }
}

class StopGroup {
  stops: Stop[];

  constructor(stops: Stop[]) {
    this.stops = stops;
  }

  format(): string {
    const firstStop = this.stops[0];
    const name = firstStop.name;
    const categories = this.stops.map(s => s.category);
    return name + ` [${categories.map(c => c.toUpperCase())}]`;
  }
}
