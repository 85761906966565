<ng-template #content let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">Informacje o przystanku</h6>
    <button (click)="modal.dismiss()" aria-label="Close" class="close" type="button">
      <img class="close-icon" src="../../../assets/icons/cancel.svg"/>
    </button>
  </div>
  <div class="modal-body pl-0 pr-0">
    <div class="container pl-2 pr-2">
      <div class="row pb-2">
        <div class="col-2"><img [src]="'assets/icons/' + stopPoint.category + '.svg'" alt=""></div>
        <div class="col-10">{{stopPoint.name}}</div>
      </div>

      <div [hidden]="!map" class="row">
        <div class="col-12">
          <div class="map demo-area w-100" id="map" style="height: 400px; width: 700px"></div>
        </div>
      </div>

      <img *ngIf="isLoading" class="w-100" src="../../../assets/icons/loading.gif"/>

    </div>

  </div>
</ng-template>






