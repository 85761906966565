import {Inject, Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map, mergeMap} from 'rxjs/operators';
import {LocationService} from '../location-service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Pattern} from './Pattern';
import {RouteClientService} from './route-client.service';
import {StopCategory} from '../model';
import {convertTo} from '../utils';
import {STORAGE_URL} from './train-stations-client-service';
import {URL_CORS} from '../../environments/environment';


const STOPS_URL = `${URL_CORS}/stops`;
export const PATTERNS_URL = STORAGE_URL + `patterns.json?alt=media&reload=${Math.random().toString(36)}`;

const STOP_INFO_KEY = 'STOPS';
export const LAST_UPDATE_STOP_DATA_KEY = 'STOP_DATA_LAST_UPDATED';

@Injectable({
  providedIn: 'root'
})
export class StopClientService {

  constructor(private http: HttpClient,
              @Inject(LOCAL_STORAGE) private storage: StorageService,
              private locationService: LocationService,
              private routeClientService: RouteClientService) {
  }

  preHeatCache(): void {
    this.http.get<any>(PATTERNS_URL)
      .subscribe(resp => {
        const patternsLastModified = resp.updated;
        if (this.isUpToDate(patternsLastModified)) {
          return;
        }
        this.routeClientService.update();
        this.updateStopsData(patternsLastModified);
      });
  }

  private isUpToDate(patternsLastModified: string): boolean {
    return this.storage.get(STOP_INFO_KEY) && patternsLastModified === this.storage.get(LAST_UPDATE_STOP_DATA_KEY);
  }

  private updateStopsData(patternsLastModified: string) {
    forkJoin([this.getStopsObservable(StopCategory.BUS), this.getStopsObservable(StopCategory.TRAM)])
      .pipe(map(([busStops, tramStops]) => busStops.concat(tramStops)),
        map(stops => stops.filter(s => s.category !== 'other')),
        mergeMap(stops => this.appendPossibleRelations(stops))
      )
      .subscribe(res => {
        this.storage.set(STOP_INFO_KEY, res);
        this.storage.set(LAST_UPDATE_STOP_DATA_KEY, patternsLastModified);
      });
  }

  findStopsByQuery(query: string): Observable<Stop[]> {
    const stops = this.getAllStops().filter(s => s.name.toLowerCase().includes(query.toLowerCase()))
      .slice(0, 10);
    return of(stops);
  }

  private getStopsObservable(category: StopCategory) {
    return this.http.get<any>(`${STOPS_URL}/${category}`)
      .pipe(map(res => res.stops));
  }

  findAllStopsInRange(range: number): Observable<Stop[]> {
    return this.locationService.getCurrentCoordinates()
      .pipe(map(coords => this.getAllStops().filter(s => s.getDistanceFromCoords(coords) < range)));
  }

  getAllStops(): Stop[] {
    return this.storage.get(STOP_INFO_KEY).map(convertTo(Stop));
  }

  private appendPossibleRelations(stops: Stop[]): Observable<Stop[]> {
    return this.http.get<Stop[]>(`${PATTERNS_URL}?alt=media&reload=${Math.random().toString(36)}`)
      .pipe(map(data => {
        stops
          .map(convertTo(Stop))
          .forEach(s => s.patterns = data
            .filter(d => convertTo(Stop).apply(d).equals(s))[0]?.patterns);
        return stops;
      }));
  }
}

export abstract class AbstractStop {
  shortName: string;
  name: string;
  category: StopCategory;
  latitude: number;
  longitude: number;
}

export class Stop extends AbstractStop {
  patterns: Pattern[];

  private static convertToDegrees(coordinate: number) {
    return coordinate / 3600000;
  }

  equals(other: Stop): boolean {
    return this.shortName === other.shortName
      && this.category === other.category;
  }

  getDistanceFromCoords(coords: Coordinates): number {
    return this.getDistanceFrom(coords.latitude, coords.longitude);
  }

  getDistanceFrom(otherLatitude: number, otherLongitude: number): number {
    const latitude = Stop.convertToDegrees(this.latitude);
    const longitude = Stop.convertToDegrees(this.longitude);
    const p = 0.017453292519943295;    // Math.PI / 180
    const cos = Math.cos;
    // tslint:disable-next-line:max-line-length
    const a = 0.5 - cos((latitude - otherLatitude) * p) / 2 + cos(otherLatitude * p) * cos((latitude) * p) * (1 - cos(((longitude - otherLongitude) * p))) / 2;
    // 2 * R; R = 6371 km
    return 12742 * Math.asin(Math.sqrt(a));
  }
}
