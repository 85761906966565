import {Injectable} from '@angular/core';
import {LocationService} from '../location-service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {GOOGLE_MAPS_API_KEY} from './places.service';
import {DirectionMode} from '../departures/direction/directionMode';
import {URL_CORS} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DirectionService {

  constructor(private locationService: LocationService,
              private http: HttpClient) {
  }

  private static formatLocationName(resp: any) {
    const components = resp.results[0].address_components;
    const streetAndNumber =
      components
        .filter(c => c.types
          .some(t => ['route', 'street_number']
            .includes(t)))
        .reverse()
        .map(a => a.short_name)
        .join(' ');
    const district =
      components
        .filter(c => c.types
          .some(t => ['sublocality', 'locality']
            .includes(t)))
        .map(a => a.short_name)
        .join(' ');
    return `${streetAndNumber}, ${district}`;
  }

  private static convertToEpochInSeconds(time: Date) {
    return Math.floor(time.valueOf() / 1000) + '';
  }

  getDirection(placeId: string, time, mode: DirectionMode): Observable<any> {
    const departureTime = DirectionService.convertToEpochInSeconds(
      mode === DirectionMode.TRANSIT
        ? time
        : new Date());
    return this.locationService.getCurrentCoordinates()
      .pipe(mergeMap(coords => this.http.get<any>(`${URL_CORS}/google/directions`, {
          params: new HttpParams()
            .set('origin', `${coords.latitude},${coords.longitude}`)
            .set('destination', `place_id:${placeId}`)
            .set('departure_time', departureTime)
            .set('key', GOOGLE_MAPS_API_KEY)
            .set('mode', mode)
            .set('language', 'pl')
        }).pipe(mergeMap(resp => {
          const route = resp.routes[0];
          if (!route) {
            return of(new Leg());
          }
          return this.resolveLocationName(coords)
            .pipe(map(address => new Leg(route.legs[0], address)));
        }))
      ));
  }

  resolveLocationName(coords: Coordinates): Observable<string> {
    return this.http.get<any>(`${URL_CORS}/google/geocode`, {
      params: new HttpParams()
        .set('latlng', `${coords.latitude},${coords.longitude}`)
        .set('language', 'pl')
        .set('key', GOOGLE_MAPS_API_KEY)
    }).pipe(map(resp => DirectionService.formatLocationName(resp)));
  }
}


export class Leg {
  duration: string;
  steps: Step[];
  departureName: string;
  arrivalTime: string;
  departureTime: string;

  constructor(obj: any = null, departureName: string = null) {
    if (!obj) {
      this.steps = [];
      return this;
    }
    this.departureName = departureName;
    this.duration = obj.duration.text;
    if (obj.departure_time) {
      this.arrivalTime = obj.arrival_time.text;
      this.departureTime = obj.departure_time.text;
    }
    this.steps = obj.steps.map(s => new Step(s));
  }
}


const WALKING = 'WALKING';
const BICYCLING = 'BICYCLING';

export class Step {

  vehicleIcon: string;

  duration: string;
  stopIcon: string;

  lineColor: string;

  line: string;
  isTransit: boolean;
  arrivalName: string;
  departureName: string;
  departureTime: string;
  arrivalTime: string;
  headSign: string;

  // TODO: refactor static methods
  constructor(obj: any) {
    Object.assign(this, obj);
    this.duration = obj.duration.text;
    this.vehicleIcon = Step.resolveVehicleIcon(obj);
    this.isTransit = Step.resolveIsTransit(obj);
    this.line = this.isTransit ? Step.resolveLine(obj) : '';
    this.stopIcon = this.isTransit ? Step.resolveStopIcon(obj) : '';
    if (this.isTransit) {
      this.resolveArrivalDeparture(obj);
      this.headSign = obj.transit_details.headsign;
      this.lineColor = obj.transit_details.line.color || 'blue';
    }
  }

  private static resolveVehicleIcon(obj: any): string {
    if (obj.travel_mode === WALKING) {
      return 'walking.svg';
    }
    if (obj.travel_mode === BICYCLING) {
      return 'biking.svg';
    }
    return `${obj.transit_details.line.vehicle.type.toLowerCase()}.svg`;
  }

  private static resolveLine(obj: any): string {
    const shortName = obj.transit_details.line.short_name;
    if (shortName) {
      return shortName;
    }
    return obj.transit_details.line.name;
  }

  private static resolveIsTransit(obj: any) {
    return obj.travel_mode !== WALKING && obj.travel_mode !== BICYCLING;
  }

  private static resolveStopIcon(obj: any) {
    return `${obj.transit_details.line.vehicle.type.toLowerCase()}_stop.png`;
  }

  private resolveArrivalDeparture(obj: any) {
    this.departureName = obj.transit_details.departure_stop.name;
    this.departureTime = obj.transit_details.departure_time.text;
    this.arrivalName = obj.transit_details.arrival_stop.name;
    this.arrivalTime = obj.transit_details.arrival_time.text;
  }
}
