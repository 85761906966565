import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import {Feature} from 'ol';
import {Polyline} from 'ol/format';
import {Icon, Stroke, Style} from 'ol/style';
import {Geometry, Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';
import {DeparturesMap, MapProvider} from '../../../map/map-provider';
import {Leg, Step} from '../../../client/direction.service';
import {Subscription, timer} from 'rxjs';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-direction-map',
  templateUrl: './direction-map.component.html',
  styleUrls: ['../../../map/map.component.less', 'direction-map.component.css']
})
export class DirectionMapComponent implements OnInit, OnDestroy {

  map: DeparturesMap;
  @Input()
  leg: Leg;
  locationSubscription: Subscription;

  constructor(private mapProvider: MapProvider) {
  }

  ngOnInit(): void {
    this.showMap();
  }

  ngOnDestroy(): void {
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
  }

  showMap() {
    this.map = this.mapProvider.provideMap(false, true);
    this.map.updateCurrentLocation();
    const layer = new VectorLayer({source: new VectorSource()});
    this.map.getLayers().insertAt(1, layer);
    const source = layer.getSource();
    this.leg.steps
      .forEach((step, index) => {
        source.addFeature(
          this.providePolyLine(step)
        );
        const isLast = index === this.leg.steps.length - 1;
        source.addFeatures([
          this.provideStartIcon(step),
          isLast ? this.provideDestinationIcon(step) : this.provideEndIcon(step)
        ]);
      });
    this.fitMap(source);
    this.locationSubscription = timer(200, 200)
      .pipe(tap(() => this.map.updateCurrentLocation()))
      .subscribe();
  }

  private providePolyLine(step: Step): Feature {
    return new class extends Feature {
      constructor(map) {
        super();
        this.setGeometry(new Polyline()
          // @ts-ignore
          .readGeometry(step.polyline.points)
          .transform('EPSG:4326', map.getView().getProjection())
        );
        this.setStyle(new Style({
          stroke: new Stroke({
            color: step.lineColor,
            width: 6,
            lineDash: step.isTransit ? null : [1, 10]
          })
        }));
      }
    }(this.map);
  }

  private provideStartIcon(step: Step): Feature {
    // @ts-ignore
    return this.provideStepIcon(step)([step.start_location.lng, step.start_location.lat]);
  }

  private provideEndIcon(step: Step): Feature {
    // @ts-ignore
    return this.provideStepIcon(step)([step.end_location.lng, step.end_location.lat]);
  }

  private provideStepIcon(step: Step): ([]) => Feature {
    return (coords: [2]) => new class extends Feature {
      constructor() {
        super();
        this.setGeometry(new Point(fromLonLat(coords)));
        this.setStyle(new Style({
          image: new Icon({
            src: `assets/icons/${step.stopIcon ? step.stopIcon : step.vehicleIcon}`,
            scale: 0.063,
          }),
        }));
      }
    }();
  }

  private fitMap(source: VectorSource<Geometry>) {
    this.map.getView().fit(source.getExtent(), {padding: [30, 30, 30, 30]});
  }

  private provideDestinationIcon(step: Step): Feature {
    return new class extends Feature {
      constructor() {
        super();
        // @ts-ignore
        this.setGeometry(new Point(fromLonLat([step.end_location.lng, step.end_location.lat])));
        this.setStyle(new Style({
          image: new Icon({
            src: 'assets/icons/stop_black.svg',
            scale: 0.9
          })
        }));
      }
    }();
  }
}
