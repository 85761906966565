import {StopCategory} from '../model';

export class Pattern {
  public stopCategory: StopCategory;

  constructor(public line: string, public destination: string) {
    if (line.length === 3) {
      this.stopCategory = StopCategory.BUS;
    } else {
      this.stopCategory = StopCategory.TRAM;
    }
  }
}
