import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LocationService} from '../location-service';
import {map, mergeMap} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {convertTo} from '../utils';
import {URL_CORS} from '../../environments/environment';

export const GOOGLE_MAPS_API_KEY = '';
const RADIUS = '25';

@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  constructor(private locationService: LocationService,
              private http: HttpClient) {
  }

  findPlaces(text: string): Observable<Place[]> {
    return this.locationService.getCurrentCoordinates()
      .pipe(mergeMap(coords => this.http.get<any>(`${URL_CORS}/google/autocomplete`, {
        params: new HttpParams()
          .set('input', text)
          .set('location', `${coords.latitude},${coords.longitude}`)
          .set('radius', RADIUS)
          .set('key', GOOGLE_MAPS_API_KEY)
          .set('language', 'pl')
      }).pipe(map(resp => resp.predictions
        .map(convertTo(Place))))));
  }
}


export class Place {
  private description: string;

  // tslint:disable-next-line:variable-name
  place_id: string;

  getDescription() {
    return this.description.replace(', Kraków, Polska', '');
  }
}
