import {Component, OnInit} from '@angular/core';
import {Place, PlacesService} from '../../client/places.service';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {DirectionService, Leg} from '../../client/direction.service';
import {DirectionMode} from './directionMode';

@Component({
  selector: 'app-direction',
  templateUrl: './direction.component.html',
  styleUrls: ['./direction.component.less']
})
export class DirectionComponent implements OnInit {

  constructor(private placesService: PlacesService,
              private directionService: DirectionService) {
  }

  leg: Leg;
  isLoading: boolean;
  place: Place;
  time: Date = new Date();
  showMap = false;
  DirectionMode = DirectionMode;
  mode = DirectionMode.TRANSIT;

  formatter(place: Place): string {
    return place.getDescription();
  }

  search = (text: Observable<string>): Observable<Place[]> => {
    return text
      .pipe(mergeMap(t => this.placesService.findPlaces(t)));
  };

  ngOnInit(): void {
  }

  findDirection(place: Place) {
    this.leg = null;
    this.place = place;
    this.isLoading = true;
    this.directionService.getDirection(place.place_id, this.time, this.mode)
      .subscribe(leg => {
        this.isLoading = false;
        this.leg = leg;
        if (!leg.steps.length) {
          this.showMap = false;
        }
      });
  }

  reloadDirection() {
    if (this.place == null) {
      return;
    }
    this.findDirection(this.place);
  }

  shouldShowSearchBar(): boolean {
    if (this.leg) {
      return false;
    }
    return !this.isLoading;
  }

  reset() {
    this.showMap = false;
    this.leg = null;
    this.place = null;
    this.time = new Date();
  }

  changeMode(mode: DirectionMode) {
    if (this.mode === mode) {
      return;
    }
    this.mode = mode;
    this.reloadDirection();
  }
}


