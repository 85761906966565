import {Injectable} from '@angular/core';
import {Departure} from '../departures/Departure';
import {map} from 'rxjs/operators';
import {merge, Observable, of} from 'rxjs';
import {DeparturesClientService} from './departures-client.service';
import {Stop} from './stop-client-service';
import {StopPointClientService} from './stop-point-client-service';


@Injectable({
  providedIn: 'root'
})
export class DepartureService {

  constructor(private departuresClientService: DeparturesClientService,
              private stopPointClientService: StopPointClientService) {
  }

  loadDeparturesFromStops(stops: Stop[]): Observable<Departure[]> {
    return stops
      .map(s => this.loadDeparturesFromStop(s))
      .reduce((a, b) => merge(a, b), of([]));
  }

  loadDeparturesFromStop(stop: Stop): Observable<Departure[]> {
    return this.stopPointClientService.findStopPointsByStop(stop)
      .map(stopPoint => {
        return this.departuresClientService.getDeparturesFromStopPoint(stopPoint)
          .pipe(map(d => d.actual),
            map(a => a.map(actual => Departure.create(stopPoint, actual)))
          );
      }).reduce((a, b) => merge(a, b), of([]));
  }
}
