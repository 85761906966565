import {Injectable} from '@angular/core';
import {StopPointClientService} from '../client/stop-point-client-service';
import {Feature} from 'ol';
import {Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';
import VectorSource from 'ol/source/Vector';


export const STOP_POINT_KEY = 'STOP_POINT';

@Injectable({
  providedIn: 'root'
})
export class StopPointMapDisplay {

  constructor(private stopPointService: StopPointClientService) {
  }

  showStopPoints(stopPointSource: VectorSource) {
    this.stopPointService
      .getAllStopPoints().forEach(stopPoint => {
      const coords = stopPoint.getCoordinates();
      const feature = new Feature({
        geometry: new Point(fromLonLat(coords)),
      });
      feature.setStyle(stopPoint.getIconStyle());
      feature.set(STOP_POINT_KEY, stopPoint);
      stopPointSource.addFeature(feature);
    });
  }
}
