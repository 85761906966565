import {Injectable} from '@angular/core';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import {XYZ} from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import {Feature, View} from 'ol';
import {Control} from 'ol/control';
import {MapOptions} from 'ol/PluggableMap';
import {Point} from 'ol/geom';
import {Icon, Style} from 'ol/style';
import VectorSource from 'ol/source/Vector';
import {LocationService} from '../location-service';
import {Coordinate} from 'ol/coordinate';
import {CompassService} from './compass.service';
import {document} from 'ngx-bootstrap/utils';
import {toRadians} from 'ol/math';
import {Mutex} from 'async-mutex';


const DEFAULT_ZOOM = 16;
export const MAP_TOKEN = 'pk.eyJ1IjoibG5pZGVja2kiLCJhIjoiY2tnZTN2MHMxMGt0MzJ5czF0dGFmZ2oyMiJ9.unb-DV-pOSqj78YKJGkTDg';

@Injectable({
  providedIn: 'root'
})
export class MapProvider {
  constructor(private locationService: LocationService,
              private compassService: CompassService) {
  }

  provideMap(centerOnStart = true, enableCompass = false): DeparturesMap {
    const map = new DeparturesMap(this.locationService, enableCompass, {
      target: 'map',
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://api.mapbox.com/styles/v1/' +
              'mapbox/streets-v11/tiles/{z}/{x}/{y}' +
              '?access_token=' +
              MAP_TOKEN,
          })
        }),
      ],
      view: new View()
    });
    if (centerOnStart) {
      map.centerToCurrentLocation();
    }

    // @ts-ignore
    const sensor = new AbsoluteOrientationSensor({frequency: 60, referenceFrame: 'device'});
    sensor.addEventListener('reading', e => {
      const rotation = this.compassService.heading(e.target);
      if (map.isCompass) {
        map.getLocationFeature().setStyle(new Style({
          image: new Icon({
            src: 'assets/icons/location.png',
            scale: 0.09,
            rotateWithView: false,
          }),
        }));
        map.getView().setRotation(toRadians(rotation));
        return;
      }
      map.getLocationFeature().setStyle(new Style({
        image: new Icon({
          src: 'assets/icons/location.png',
          scale: 0.09,
          rotateWithView: true,
          rotation: toRadians(-rotation)
        }),
      }));
      if (map.isCompass) {
        map.getView().setRotation(-rotation);
      }
    });
    sensor.start();
    return map;
  }

}

export class DeparturesMap extends Map {
  source = new VectorSource();
  isCompass = false;
  enableCompass;

  constructor(private locationService: LocationService,
              enableCompass: boolean,
              options: MapOptions) {
    super(options);
    this.enableCompass = enableCompass;
    this.addLayer(
      new VectorLayer({
        source: this.source
      }));
    this.addControl(this.provideFullScreenButton());
    this.addControl(this.provideLocationButton());
  }

  updateCurrentLocation(): void {
    this.locationService
      .getOSMCoordinates()
      .subscribe(currentPosition => {
        this.showCurrentLocationPin(currentPosition);
        if (this.isCompass) {
          this.getView().setCenter(currentPosition);
        }
      });
  }

  centerToCurrentLocation(): void {
    this.locationService
      .getOSMCoordinates()
      .subscribe(location => {
        this.showCurrentLocationPin(location);
        this.setCenter(location);
      });
  }

  addNewFeature(coords: number[], style: Style): Feature {
    const feature = new Feature({
      geometry: new Point(coords),
    });
    feature.setStyle(style);
    this.source.addFeature(feature);
    return feature;
  }

  setCenter(coords: Coordinate): void {
    this.getView().animate({
      center: coords,
      zoom: DEFAULT_ZOOM,
      duration: 1000,
    });
  }

  getFeatureById(id: string): Feature {
    return this.source.getFeatureById(id);
  }

  addFeature(feature: Feature) {
    this.source.addFeature(feature);
  }

  removeFeature(feature: Feature) {
    this.source.removeFeature(feature);
  }

  getLocationFeature(): Feature {
    const featureId = 'location';
    const existingFeature = this.source.getFeatureById(featureId);
    if (existingFeature) {
      return existingFeature;
    } else {
      const feature = new Feature();
      feature.setId(featureId);
      this.source.addFeature(feature);
      return feature;
    }
  }

  private provideFullScreenButton(): Control {
    const enableButton = '<button><i class="fa fa-expand"></i></button>';
    const disableButton = '<button><i class="fa fa-compress"></button>';
    const fullScreen = document.createElement('div');
    fullScreen.className = 'ol-full-screen ol-unselectable ol-control';
    fullScreen.innerHTML = enableButton;
    fullScreen.addEventListener('click', () => {
      const fullScreenClass = 'full-screen-map';
      const mapSelector = document.querySelector('.map-container');
      if (mapSelector.classList.contains(fullScreenClass)) {
        fullScreen.innerHTML = enableButton;
        mapSelector.classList.remove(fullScreenClass);
      } else {
        fullScreen.innerHTML = disableButton;
        mapSelector.classList.add(fullScreenClass);
      }
      this.updateSize();
    });
    return new Control({
      element: fullScreen
    });
  }

  private provideLocationButton(): Control {
    const locationButton = '<button><i class="fa fa-location-arrow"></button>';
    const compassButton = '<button><i class="fa fa-compass"></button>';
    const locate = document.createElement('div');
    locate.className = 'ol-control ol-unselectable ol-locate';
    locate.innerHTML = locationButton;
    const mutex = new Mutex();
    locate.addEventListener('click', () => {
      mutex.runExclusive(() => {
        if (this.isCompass) {
          this.getInteractions().forEach(interaction => interaction.setActive(true));
          locate.innerHTML = locationButton;
          this.isCompass = false;
          document.querySelector('.map-container').classList.remove('hide-rotate');
          return;
        }
        if (this.isCentered() && this.enableCompass) {
          this.isCompass = true;
          document.querySelector('.map-container').classList.add('hide-rotate');
          this.getInteractions().forEach(interaction => interaction.setActive(false));
          locate.innerHTML = compassButton;
          return;
        }
        this.centerToCurrentLocation();
      }).then();
    });
    return new Control({
      element: locate
    });
  }

  private isCentered() {
    // @ts-ignore
    return _.isEqual(this.getLocationFeature().getGeometry().getCoordinates(),
      this.getView().getCenter());
  }

  private showCurrentLocationPin(currentPosition: number[]): void {
    this.getLocationFeature().setGeometry(new Point(currentPosition));
  }
}
