import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    (<any> window).twttr.widgets.load();

  }

}
