import {Inject, Injectable} from '@angular/core';
import {Stop} from './stop-client-service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Pattern} from './Pattern';
import * as _ from 'lodash';
import {FAVOURITE_ROUTES_KEY, StopCategory} from '../model';
import {convertTo} from '../utils';


@Injectable({
  providedIn: 'root'
})
export class FavouriteRoutesService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  getRoutes(): FavouriteRoute[] {
    if (!this.storage.get(FAVOURITE_ROUTES_KEY)) {
      this.setRoutes([]);
    }
    return this.storage.get(FAVOURITE_ROUTES_KEY).map(c => new FavouriteRoute(c));
  }

  addRoute(route: FavouriteRoute): void {
    const routes = this.getRoutes();
    routes.push(route);
    this.setRoutes(routes);
  }

  deleteRoute(route: FavouriteRoute): void {
    const routes = this.getRoutes();
    this.setRoutes(routes.filter(r => r.toStops[0].name !== route.toStops[0].name
      || r.fromStops[0].name !== route.fromStops[0].name));
  }

  private setRoutes(criteria: FavouriteRoute[]) {
    this.storage.set(FAVOURITE_ROUTES_KEY, criteria);
  }
}

export class FavouriteRoute {
  public fromStops: Stop[];
  public toStops: Stop[];
  patterns: Pattern[];

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (obj) {
      this.fromStops = obj.fromStops.map(convertTo(Stop));
      this.toStops = obj.toStops.map(convertTo(Stop));
    }
  }

  getCategories(): StopCategory [] {
    const categories = this.patterns.map(p => p.stopCategory);
    return _.uniq(categories);
  }
}

