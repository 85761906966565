import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-not-found',
  template: `
    <div class="container departure">
      <div class="row pt-1 pb-2 text-center">
        <div class="col-12">
          <img class="w-25" src="assets/icons/empty.svg"/>
        </div>
      </div>
      <div class="row pt-2 pb-2 text-center">
        <div class="col-12">
          {{description}}
        </div>
      </div>
    </div>
  `,
})
export class NotFoundComponent {
  @Input()
  description: string;
}
