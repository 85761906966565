<div class="container settings">
  <div class="row font-weight-bold  pt-2 pb-2 text-center">
    <div class="col-12">
      <label for="slider">Pokazuj ulubione w promieniu:</label> <span
      style="color: #080773"> {{range}} km</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input (change)="updateRange()" [(ngModel)]="range" class="custom-range" id="slider" max="10" min="0" step="0.1"
             type="range">
    </div>
  </div>
  <div class="row   pt-2 pb-2 text-center">
    <div class="col-6">
      Ostatnia aktualizacja bazy:
    </div>
    <div class="col-6">
      {{stopDataLastModified  | date: 'dd/MM/yyyy HH:mm'}}
    </div>
  </div>

  <div class="row font-weight-bold  pt-2 pb-2 text-center">
    <div class="col-12">
      Ulubione trasy:
    </div>
  </div>
  <div *ngFor="let favouriteRoute of favouriteRoutes;" class="row border-top pt-2 pb-2 mt-2">

    <div class="col-4 font-weight-bold">{{favouriteRoute.fromStops[0].name}} -> {{favouriteRoute.toStops[0].name}}
      <br>

      <ng-container *ngFor=" let category of favouriteRoute.getCategories() ">
        <span *ngIf="category === 'tram'">
          <img alt="" src="assets/icons/tram.svg"/>
        </span>
        <span *ngIf="category == 'bus'">
          <img alt="" src="assets/icons/bus.svg">
        </span>
      </ng-container>
    </div>
    <br>


    <div class="col-5">
      <ng-container *ngFor="let relation of favouriteRoute.patterns">
        {{relation.line}} -> {{relation.destination}} <br>
      </ng-container>
      <ng-container *ngIf="favouriteRoute.patterns.length == 0 ">
        Aktualnie brak linii obslugujących trasę
      </ng-container>
    </div>
    <div class="col-1">
      <img (click)="delete(favouriteRoute); updateRoutes()" src="assets/icons/minus.svg"/> <br>
    </div>
  </div>
  <div class="row pt-5">
    <div class="col-12 text-center">
      <a routerLink="add-route">
        <img src="assets/icons/plus.svg"/>
      </a>
    </div>
  </div>
</div>
