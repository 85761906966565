<ng-template #content let-modal>
  <div [ngClass]="{ 'modal-header' : true, 'pb-1': goBackAction}" class="modal-header mb-0 pb-1">
    <button (click)="modal.dismiss(); goBackAction();" *ngIf="goBackAction" aria-label="Close"
            class="close align-text-left ml-0 pl-0"
            type="button">
      <img class="close-icon" src="assets/icons/left-arrow.svg"/>
    </button>
    <h6 class="modal-title text-center" id="modal-basic-title">Odjazdy z przystanku</h6>
    <button (click)="dismiss()" aria-label="Close"
            class="close ml-0" type="button">
      <img class="close-icon" src="assets/icons/cancel.svg"/>
    </button>
  </div>
  <div class="modal-body pr-0 pl-0">
    <div class="container pr-0">
      <div (click)="showStopLocation()" class="row pb-2">
        <div class="col-2"><img [src]="'assets/icons/' + stop.category + '.svg'" alt=""></div>
        <div class="col-10">{{stop.name}}</div>
      </div>

      <img *ngIf="isLoading" class="w-100" src="../../../assets/icons/loading.gif"/>

      <ng-container *ngFor="let actual of actuals;">
        <div (click)="showVehicle(actual.getVehicle()); goBackActionToStop()" class="row mb-3 mt-3 depart">
          <div class="col-1">
            <img *ngIf="actual.low == ForDisabled.YES" alt="" src="../../../assets/icons/disabled.svg">
            <img *ngIf="actual.low == ForDisabled.HALF" alt="" src="../../../assets/icons/disabled-half.svg">
            <img *ngIf="actual.low == ForDisabled.NO" alt="" src="../../../assets/icons/disabled-no.svg">
          </div>
          <div class="col-7">
            <b>{{actual.patternText.padEnd(2)}}</b> -> {{actual.direction}}
          </div>
          <div *ngIf="actual.actualTime" class="col-2 pl-0 pr-0 mr-0">
            {{actual.mixedTime.split(' ')[0].padEnd(2)}} min
          </div>

          <div *ngIf="!actual.actualTime" class="col-2 pl-0 pr-0 mr-0">
          </div>

          <div class="col-2 pr-0 pl-0 mr-0">
            {{actual.actualTime ? actual.actualTime : actual.plannedTime}}
          </div>
        </div>

      </ng-container>

    </div>

  </div>
</ng-template>

<ng-template #stop_points_content let-modal>
  <div [ngClass]="{ 'modal-header' : true, 'pb-1': goBackAction}" class="modal-header mb-0 pb-1">
    <button (click)="modal.dismiss(); goBackAction();" *ngIf="goBackAction" aria-label="Close"
            class="close align-text-left ml-0 pl-0"
            type="button">
      <img class="close-icon" src="assets/icons/left-arrow.svg"/>
    </button>
    <h6 class="modal-title text-center">{{stop.name}}</h6>
    <button (click)="dismiss()" aria-label="Close"
            class="close ml-0" type="button">
      <img class="close-icon" src="assets/icons/cancel.svg"/>
    </button>
  </div>
  <div class="modal-body pr-0 pl-0">
    <div class="container pr-0">
      <img *ngIf="isLoading" class="w-100" src="../../../assets/icons/loading.gif"/>
      <div [hidden]="!map" class="row">
        <div class="col-12">
          <div class="map demo-area" id="map2" style="height: 400px; width: 98%"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #vehicle_content let-modal>
  <div [ngClass]="{ 'modal-header' : true, 'pb-1': goBackAction}" class="modal-header mb-0 pb-1">
    <button (click)="modal.dismiss(); goBackAction();" *ngIf="goBackAction" aria-label="Close"
            class="close align-text-left ml-0 pl-0"
            type="button">
      <img class="close-icon" src="assets/icons/left-arrow.svg"/>
    </button>
    <h6 class="modal-title text-center" id="modal-basic-vehicle-title">{{title}}</h6>
    <button (click)="dismiss()" aria-label="Close"
            class="close ml-0" type="button">
      <img class="close-icon" src="assets/icons/cancel.svg"/>
    </button>
  </div>
  <div class="modal-body pl-0">

    <app-not-found *ngIf="!isLoading && !vehicleModel && !departures.length" description="Brak informacji">
    </app-not-found>

    <div class="container nearest">
      <div *ngIf="vehicleModel" class="row ">
        <div class="col-5">
          model:
        </div>
        <div class="col-7 font-weight-bold pr-0">
          {{vehicleModel.type}}
        </div>
      </div>
      <div (click)="copyNumToClipBoard()" *ngIf="vehicleModel" class="row">
        <div class="col-5">
          numer boczny:
        </div>
        <div class="col-7 font-weight-bold pr-0">
          {{vehicleModel.num}}
        </div>
      </div>
      <div *ngIf="shopClipboardInfo" class="row fadeInFadeOut">
        <div class="col-12 btn-success ml-2">
          <i class="fa fa-check"></i>
          Skopiowano numer boczny do schowka
        </div>
      </div>

      <div *ngIf="vehicleModel" class="row pt-2">
        <div class="col-3">
          <img *ngIf="vehicleModel.low == ForDisabled.YES" alt="" src="assets/icons/disabled.svg">
          <img *ngIf="vehicleModel.low == ForDisabled.HALF" alt="" src="assets/icons/disabled-half.svg">
          <img *ngIf="vehicleModel.low == ForDisabled.NO" alt="" src="assets/icons/disabled-no.svg">
        </div>
        <div class="col-9">
          <span *ngIf="vehicleModel.low == ForDisabled.YES">niskopodłogowy</span>
          <span *ngIf="vehicleModel.low == ForDisabled.HALF">częściowo niskopdłogowy</span>
          <span *ngIf="vehicleModel.low == ForDisabled.NO">brak niskiej podłogi</span>
        </div>
      </div>

      <div *ngIf="vehicleModel" class="row pt-2">
        <div class="col-12">
          <img *ngIf="vehicleModel.getPictureLocation()" [src]="vehicleModel.getPictureLocation()"
               class="mw-100 mb-2">
        </div>
      </div>

      <img *ngIf="isLoading" class="w-100" src="assets/icons/loading.gif"/>

      <ng-container *ngFor="let departure of departures">
        <div (click)="showStopByName(departure.getStopName())" [ngClass]="{'row pt-1': true,
                          'font-weight-bold': departure.status === 'PREDICTED' || departure.status === 'STOPPING',
                          'text-danger': departure.status === 'STOPPING'
                          }"
             class="depart">
          <div *ngIf="departure.status === 'STOPPING'" class="col-2">
            <img src="assets/icons/stop.svg" width="15em">
          </div>
          <div *ngIf="departure.status === 'DEPARTED'" class="col-2">
            <img src="assets/icons/stop_grey.svg" width="15em">
          </div>
          <div *ngIf="['PREDICTED', 'PLANNED'].includes(departure.status)" class="col-2">
            <img src="assets/icons/stop_black.svg" width="15em">
          </div>
          <div *ngIf="departure.status != 'PLANNED'" class="col-2 ml-0 pl-0 mr-0 pr-0">
            {{departure.timeToArrive }}
          </div>
          <div class="col-6 mr-0 pr-0">
            {{departure.getStopName()}}
          </div>
          <div class="col-2">
            {{departure.getTime()}}
          </div>
        </div>
      </ng-container>
    </div>

  </div>
</ng-template>








