import {Component, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StopPoint, StopPointClientService} from '../../client/stop-point-client-service';
import {DeparturesClientService} from '../../client/departures-client.service';
import {Actual, DeparturesFromStop, Vehicle} from '../../model';
import {ForDisabled} from '../../departures/Departure';
import {VehicleModel} from '../../vehicle/vehicle-model';
import {TripDeparture, TripInfoService} from '../../client/trip-client.service';
import {VehicleService} from '../../vehicle/vehicle.service';
import {AbstractStop, Stop, StopClientService} from '../../client/stop-client-service';
import {Observable} from 'rxjs';
import {fromLonLat} from 'ol/proj';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import {XYZ} from 'ol/source';
import {MAP_TOKEN} from '../../map/map-provider';
import {Overlay, View} from 'ol';
import OverlayPositioning from 'ol/OverlayPositioning';

@Component({
  selector: 'app-stop-departures',
  templateUrl: './stop-departures.component.html',
  styleUrls: ['./stop-departures.component.less']
})
export class StopDeparturesComponent {

  @ViewChild('content') stopContent: any;
  @ViewChild('vehicle_content') vehicleContent: any;
  @ViewChild('stop_points_content') stopLocationContent: any;

  stop: AbstractStop;
  actuals: Actual[];
  isLoading = false;
  vehicleModel: VehicleModel;
  vehicle: Vehicle;
  ForDisabled = ForDisabled;
  title: string;
  map: Map;
  animationTimeouts: number[] = [];

  departures: TripDeparture[];
  goBackAction: () => void;
  shopClipboardInfo: boolean;
  stopPoints: StopPoint[];

  constructor(private vehicleService: VehicleService,
              private tripInfoService: TripInfoService,
              private stopClientService: StopClientService,
              private stopPointClientService: StopPointClientService,
              private modalService: NgbModal,
              private departuresClientService: DeparturesClientService) {
  }

  showVehicle(vehicle: Vehicle): void {
    this.shopClipboardInfo = false;
    this.vehicle = vehicle;
    this.modalService.dismissAll();
    this.isLoading = true;
    this.departures = [];
    this.title = vehicle.getDirection();
    this.vehicleService.findAllVehicleModels().then(vehicles => {
      this.vehicleModel = vehicles[vehicle.id];
    });
    this.modalService.open(this.vehicleContent);
    this.tripInfoService.getTripInfo(vehicle)
      .subscribe(departures => {
        this.departures = departures;
        this.isLoading = false;
      });
  }

  showStopPoint(stopPoint: StopPoint): void {
    this.stop = stopPoint;
    this.actuals = [];
    this.modalService.open(this.stopContent);
    const departures = this.departuresClientService.getDeparturesFromStopPoint(stopPoint);
    this.appendDepartures(departures);
  }

  showStopByName(name: string) {
    const stop = this.stopClientService.getAllStops()
      .filter(s => s.name === name)
      .filter(s => s.category === this.vehicle.category)
      [0];
    this.modalService.dismissAll();
    this.modalService.open(this.stopContent);
    this.stop = stop;
    const departures = this.departuresClientService.getDeparturesFromStop(stop);
    this.goBackAction = () => {
      this.showVehicle(this.vehicle);
      this.goBackAction = null;
    };
    this.appendDepartures(departures);
  }

  showStopLocation() {
    this.modalService.dismissAll();
    this.stopPoints = this.stopPointClientService.getAllStopPoints()
      .filter(s => s.shortName === this.stop.shortName)
      .filter(s => s.category === this.stop.category);
    this.goBackActionToStop();
    this.modalService.open(this.stopLocationContent);
    this.appendMap();
  }

  private appendMap() {
    this.map = new Map({
      target: 'map2',
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://api.mapbox.com/styles/v1/' +
              'mapbox/streets-v11/tiles/{z}/{x}/{y}' +
              '?access_token=' +
              MAP_TOKEN
          })
        }),
      ],
      view: new View({
        center: fromLonLat(this.stopPoints[0].getCoordinates()),
        zoom: 18
      })
    });
    this.stopPoints.map(stopPoint => {
      const pin = document.createElement('div');
      pin.innerHTML = `<img src="assets/icons/${stopPoint.category}_stop.png"  style="width: 12vw"  />`;
      return new Overlay({
        element: pin,
        positioning: OverlayPositioning.BOTTOM_CENTER,
        position: fromLonLat(stopPoint.getCoordinates())
      });
    }).forEach(a => this.map.addOverlay(a));
    this.map.updateSize();
  }

  goBackActionToStop() {
    this.goBackAction = () => {
      if (!this.stop) {
        return;
      }
      if (this.stop instanceof StopPoint) {
        this.showStopPoint(this.stop);
      }
      if (this.stop instanceof Stop) {
        this.showStopByName(this.stop.name);
      }
      this.goBackAction = null;
    };
  }

  copyNumToClipBoard() {
    navigator.clipboard.writeText(this.vehicleModel.num).then();
    this.shopClipboardInfo = true;
    this.animationTimeouts.forEach(clearTimeout);
    const timeout = setTimeout(() => {
      this.shopClipboardInfo = false;
    }, 3000);
    this.animationTimeouts.push(timeout);
  }

  dismiss() {
    this.modalService.dismissAll();
    this.stop = null;
    this.vehicle = null;
    this.goBackAction = null;
  }

  private appendDepartures(departures: Observable<DeparturesFromStop>) {
    this.isLoading = true;
    departures
      .subscribe(data => {
        this.actuals = data.actual;
        this.isLoading = false;
      });
  }
}
