import {Component, Inject, Injectable, OnInit} from '@angular/core';
import {FavouriteRoute, FavouriteRoutesService} from '../client/favourite-routes.service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {LAST_UPDATE_STOP_DATA_KEY} from '../client/stop-client-service';


const RANGE_STORAGE_KEY = 'RANGE';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
@Injectable({
  providedIn: 'root'
})
export class SettingsComponent implements OnInit {

  favouriteRoutes: FavouriteRoute[];
  range: number;
  stopDataLastModified: any;

  constructor(private favouriteRoutesService: FavouriteRoutesService,
              @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.updateRoutes();
    this.range = this.getRange();
    this.stopDataLastModified = this.storage.get(LAST_UPDATE_STOP_DATA_KEY);
  }

  updateRange() {
    this.storage.set(RANGE_STORAGE_KEY, this.range);
  }

  getRange() {
    const range = this.storage.get(RANGE_STORAGE_KEY);
    return range ? range : 1;
  }

  ngOnInit() {
  }

  delete(route: FavouriteRoute) {
    this.favouriteRoutesService.deleteRoute(route);
  }

  private updateRoutes() {
    this.favouriteRoutes = this.favouriteRoutesService.getRoutes();
  }
}
