import {Component, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StopPoint} from '../../client/stop-point-client-service';
import TileLayer from 'ol/layer/Tile';
import {XYZ} from 'ol/source';
import {Overlay, View} from 'ol';
import Map from 'ol/Map';
import {fromLonLat} from 'ol/proj';
import OverlayPositioning from 'ol/OverlayPositioning';
import {MAP_TOKEN} from '../../map/map-provider';


@Component({
  selector: 'app-stop-pin',
  templateUrl: './stop-pin.component.html',
  styleUrls: ['./stop-pin.component.less']
})
export class StopPinComponent {

  stopPoint: StopPoint;

  @ViewChild('content') content: any;

  map: Map;
  isLoading = false;

  constructor(private modalService: NgbModal) {
  }


  show(stopPoint: StopPoint): void {
    this.stopPoint = stopPoint;
    this.modalService.open(this.content);
    this.appendMap(stopPoint);
  }

  private appendMap(stopPoint: StopPoint) {
    const position = fromLonLat(stopPoint.getCoordinates());
    this.map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://api.mapbox.com/styles/v1/' +
              'mapbox/streets-v11/tiles/{z}/{x}/{y}' +
              '?access_token=' +
              MAP_TOKEN
          })
        }),
      ],
      view: new View({
        center: position,
        zoom: 18
      })
    });

    const pin = document.createElement('div');
    pin.innerHTML = `<img src="assets/icons/${stopPoint.category}_stop.png"  style="width: 12vw"  />`;
    const overlay = new Overlay({
      element: pin,
      positioning: OverlayPositioning.BOTTOM_CENTER,
      position
    });
    this.map.addOverlay(overlay);
    this.map.updateSize();
  }
}
