<div *ngIf="departures.filter(this.phraseFilter).length" class="container departure">
  <div *ngIf="!isLoading" class="row pb-2">
    <div class="col-7">Ostatnia aktualizacja:</div>
    <div class="col-5 font-weight-bold">{{lastUpdated | date:'HH:mm:ss '}}</div>
  </div>

  <div class="row font-weight-bold  pt-2 pb-2">
    <div class="col-3">
      Linia
    </div>
    <div class="col-4">
      Przystanek
    </div>
    <div class="col-3">
      Kierunek
    </div>
    <div class="col-1">
      Czas
    </div>
  </div>
  <div *ngFor="let departure of departures.filter(this.phraseFilter);"
       class="row border-top pt-2 pb-2 mt-2">
    <div (click)="showVehicleInfo(departure);" class="col-1">
      <img [src]="'assets/icons/' + departure.stopPoint.category + '.svg'" alt=""/>
    </div>

    <div (click)="showVehicleInfo(departure)" *ngIf="departure.forDisabled == ForDisabled.YES" class="col-1"><img alt=""
                                                                                                                  src="assets/icons/disabled.svg">
    </div>
    <div (click)="showVehicleInfo(departure)" *ngIf="departure.forDisabled == ForDisabled.HALF" class="col-1"><img
      alt=""
      src="assets/icons/disabled-half.svg">
    </div>
    <div (click)="showVehicleInfo(departure)" *ngIf="departure.forDisabled == ForDisabled.NO" class="col-1">
      <img alt=""
           src="assets/icons/disabled-no.svg">
    </div>
    <div (click)="showVehicleInfo(departure)" *ngIf="departure.forDisabled == undefined" class="col-1">
    </div>

    <div (click)="showVehicleInfo(departure)" class="col-1 font-weight-bold">{{departure.line}}</div>
    <div (click)="stopPinComponent.show(departure.stopPoint)" class="col-4">{{departure.stop}}</div>
    <div class="col-3">{{departure.destination}}</div>
    <div *ngIf="departure.minutesToDepart" class="col-1 text-nowrap">{{departure.minutesToDepart}} min</div>
    <div *ngIf="!departure.minutesToDepart" class="col-1">{{departure.plannedTime}}</div>
  </div>
</div>

<img *ngIf="isLoading" class="w-100" src="assets/icons/loading.gif"/>

<app-not-found *ngIf="!isLoading && !departures.filter(this.phraseFilter).length"
               description="Brak odjazdów dla podanych kryteriów">
</app-not-found>
<app-stop-pin></app-stop-pin>
<app-stop-departures></app-stop-departures>
