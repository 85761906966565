import {Component, OnInit, ViewChild} from '@angular/core';
import {DepartureService} from '../../client/departure.service';
import {DeparturesComponent} from '../departures.component';
import {map, mergeMap} from 'rxjs/operators';
import {FavouriteRoute, FavouriteRoutesService} from '../../client/favourite-routes.service';
import {LocationService} from '../../location-service';
import {SettingsComponent} from '../../settings/settings.component';
import {merge, Observable, of} from 'rxjs';
import {Departure} from '../Departure';
import {StopClientService} from '../../client/stop-client-service';

@Component({
  selector: 'app-favourite-departures',
  templateUrl: './favourite-departures.component.html',
  styleUrls: ['./favourite-departures.component.less']
})
export class FavouriteDeparturesComponent implements OnInit {

  @ViewChild(DeparturesComponent, {static: true}) departuresComponent: DeparturesComponent;

  constructor(private favouriteRoutesService: FavouriteRoutesService,
              private departureService: DepartureService,
              private stopClientService: StopClientService,
              private locationService: LocationService,
              private settings: SettingsComponent) {
  }

  ngOnInit(): void {
    const observableDepartures = this.locationService.getCurrentCoordinates()
      .pipe(map(coords => this.findNearestRoutes(coords)),
        mergeMap(route => this.getMatchingDepartures(route)));
    this.departuresComponent.scheduleLoading(observableDepartures);
  }

  private findNearestRoutes(coords: Coordinates): FavouriteRoute[] {
    return this.favouriteRoutesService.getRoutes()
      .filter(c => c.fromStops[0].getDistanceFromCoords(coords) < this.settings.getRange());
  }

  private getMatchingDepartures(routes: FavouriteRoute[]): Observable<Departure[]> {
    return routes.map(route =>
      this.departureService.loadDeparturesFromStops(route.fromStops)
        .pipe(map(d => d.filter(d2 => d2.matchesPatterns(route.patterns))))
    ).reduce((a, b) => merge(a, b), of([]));
  }
}
