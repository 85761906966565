import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {StopClientService} from './client/stop-client-service';
import {StopPointClientService} from './client/stop-point-client-service';
import {TrainStationsClientService} from './client/train-stations-client-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  constructor(private stopClientService: StopClientService,
              private stopPointClientService: StopPointClientService,
              private trainStationsClientService: TrainStationsClientService) {
  }

  ngOnInit(): void {
    this.stopClientService.preHeatCache();
    this.stopPointClientService.preHeatCache();
    this.trainStationsClientService.preHeatCache();
  }
}
