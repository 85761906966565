const MILLISECONDS_IN_MINUTE = 1000 * 60;

export class TimeCalculator {
  static timeFromNow(date): string {
    const minutes = (date.valueOf() - new Date().valueOf())
      / MILLISECONDS_IN_MINUTE;
    if (minutes < 1) {
      return '';
    }
    const hours = Math.floor(minutes / 60);
    return `${hours > 0 ? hours + ' h ' : ''}${Math.floor(minutes) % 60} min`;
  }
}
