export function convertTo(newObjectClass) {
  return (obj?: any) => {
    const newObject = Object.create(newObjectClass.prototype);
    Object.assign(newObject, obj);
    return newObject;
  };
}

export function flatten() {
  return (accumulator, value) => accumulator.concat(value);
}
