export class VehicleModel {
  num: string;
  type: string;
  low: number;

  getPictureLocation(): string {
    const isPicAvailable = vehiclesWithPictures.map(v => v.trim().toLowerCase())
      .includes(this.type.trim().toLowerCase());
    if (isPicAvailable) {
      return `assets/vehicles_pics/${this.type.split(' ').join('_')}.jpg`;
    }
  }
}

const vehiclesWithPictures = [
  '2014N',
  'Solaris Urbino 12 IV',
  'Solaris Urbino 12 III',
  'Solaris Urbino 12 IV Electric',
  'Solaris Urbino 18 IV',
  'Solaris Urbino 18 IV Electric',
  'Solaris Urbino 18 III Hybrid',
  'NGT8',
  '105N',
  'GT8N',
  'NGT6 (1)',
  'NGT6 (2)',
  'NGT6 (3)',
  'GT8S',
  'Autosan M09LE',
  'Mercedes O530 C2',
  'Mercedes O530 C2 Hybrid',
  'Mercedes Conecto G',
  'Mercedes O530',
  'Volvo 7900A Hybrid',
  'Stadler Tango',
  'Stadler Tango II',
  'E1',
  'N8S-NF',
  'EU8N'];
