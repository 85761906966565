import {Component, ViewChild} from '@angular/core';
import {Leg, TrainDeparture, TrainStationsClientService} from '../../client/train-stations-client-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-train-trip',
  templateUrl: './train-trip.component.html',
  styleUrls: ['./train-trip.component.less']
})
export class TrainTripComponent {

  @ViewChild('train_trip_content') content: any;
  legs: Leg[];
  title: string;
  goBackAction: () => void;

  constructor(
    private modalService: NgbModal,
    private trainStationsClientService: TrainStationsClientService) {
  }

  async showLegs(departure: TrainDeparture, goBackAction: () => void) {
    this.legs = await this.trainStationsClientService
      .getLegsForDeparture(departure);
    this.title = `${departure.line} -> ${departure.headsign}`;
    this.modalService.open(this.content);
    this.goBackAction = goBackAction;
  }
}
