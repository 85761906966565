import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Vehicle} from '../model';
import {map} from 'rxjs/operators';
import {fromLonLat} from 'ol/proj';
import {URL_CORS} from '../../environments/environment';

const CONVERSION_DENOMINATOR = 3600000.0;

@Injectable({
  providedIn: 'root'
})
export class PathClientService {

  constructor(private http: HttpClient) {
  }

  findPath(vehicle: Vehicle): Observable<any[]> {
    return this.http.get<any>(`${URL_CORS}/path-info/${vehicle.category}?id=${vehicle.id}`)
      .pipe(map(resp => resp.paths[0].wayPoints))
      .pipe(map(wayPoints => wayPoints
        .map(wayPoint => fromLonLat([wayPoint.lon / CONVERSION_DENOMINATOR, wayPoint.lat / CONVERSION_DENOMINATOR]))));
  }

}
