import {Component, ViewChild} from '@angular/core';
import {TrainDeparture, TrainStation, TrainStationsClientService} from '../../client/train-stations-client-service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TrainTripComponent} from '../train-trip/train-trip.component';
import {Feature} from 'ol';
import {Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';


export const TRAIN_STATION_KEY = 'TRAIN_STATION';

@Component({
  selector: 'app-train-station',
  templateUrl: './train-station.component.html',
  styleUrls: ['./train-station.component.css']
})
export class TrainStationComponent {

  trainStation: TrainStation;
  departures: TrainDeparture[];
  @ViewChild('train_station_content') content: any;
  @ViewChild(TrainTripComponent) trainTripComponent: TrainTripComponent;
  isUpdateInProcess: boolean;

  constructor(private modalService: NgbModal,
              private trainStationsClientService: TrainStationsClientService) {
  }

  addTrainStationPins(add: (feature: Feature) => void) {
    this.trainStationsClientService
      .getAll()
      .subscribe(stations => {
        stations.forEach(station => {
          const coords = station.getCoordinates();
          const feature = new Feature({
            geometry: new Point(fromLonLat(coords)),
          });
          feature.setStyle(station.getIconStyle());
          feature.set(TRAIN_STATION_KEY, station);
          add(feature);
        });
      });
  }

  show(trainStation: TrainStation): void {
    this.showInfo(trainStation);
    this.trainStationsClientService
      .isUpdateInProcess
      .subscribe(isUpdateInProcess => {
        this.isUpdateInProcess = isUpdateInProcess;
        if (isUpdateInProcess) {
          return;
        }
        this.trainStationsClientService
          .getTrainStationById(this.trainStation.id)
          .then(station => {
            this.showInfo(station);
          });
      });
    this.modalService.open(this.content);
  }

  private showInfo(trainStation: TrainStation) {
    this.trainStation = trainStation;
    this.departures = trainStation.getNextDepartures();
  }

  async showLegs(departure: TrainDeparture) {
    this.modalService.dismissAll();
    await this.trainTripComponent.showLegs(departure,
      () => {
        this.show(this.trainStation);
      });
  }
}
