import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompassService {

  heading(orientation) {
    const q = orientation.quaternion;
    return Math.atan2(2 * q[0] * q[1] + 2 * q[2] * q[3], 1 - 2 * q[1] * q[1] - 2 * q[2] * q[2]) * (180 / Math.PI);
  }
}
