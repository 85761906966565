import {Component, OnInit, ViewChild} from '@angular/core';
import {Departure, ForDisabled} from './Departure';
import {DepartureService} from '../client/departure.service';
import {VehicleService} from '../vehicle/vehicle.service';
import {forkJoin, interval, Observable, Subscription} from 'rxjs';
import {startWith, tap} from 'rxjs/operators';
import {StopPinComponent} from '../stop/pin/stop-pin.component';
import {StopDeparturesComponent} from '../stop/departures/stop-departures.component';


const REFRESH_PERIOD = 30_0000;

@Component({
  selector: 'app-departures',
  templateUrl: './departures.component.html',
  styleUrls: ['./departures.component.less']
})
export class DeparturesComponent implements OnInit {

  @ViewChild(StopDeparturesComponent) stopDeparturesComponent: StopDeparturesComponent;
  @ViewChild(StopPinComponent) stopPinComponent: StopPinComponent;

  ForDisabled = ForDisabled;
  isLoading = true;
  departures: Departure[] = [];
  phraseFilter: (departure: Departure) => boolean;
  departuresSubscription: Subscription;
  lastUpdated: Date;

  constructor(private departureService: DepartureService,
              private vehicleService: VehicleService) {
  }

  private static compareDepartures(a: Departure, b: Departure) {
    return a.getAvailableTime().localeCompare(b.getAvailableTime());
  }

  ngOnInit(): void {
    this.applyFilter('');
  }

  scheduleLoading(observable: Observable<Departure[]>): void {
    if (this.departuresSubscription) {
      this.departuresSubscription.unsubscribe();
    }
    this.departuresSubscription = interval(REFRESH_PERIOD)
      .pipe(startWith(0))
      .pipe(tap(() => {
        this.isLoading = true;
        this.departures = [];
        forkJoin([observable.pipe(tap(departures => this.appendDepartures(departures)))])
          .subscribe(() => this.completeLoad());
      }))
      .subscribe();
  }

  applyFilter(phrase: string) {
    this.phraseFilter = departure => {
      return [departure.line, departure.stop, departure.destination,
        departure.line, departure.destination, departure.stop]
        .filter(e => e)
        .map(s => s.toLowerCase())
        .join(' ')
        .includes(phrase.toLowerCase());
    };
  }

  showVehicleInfo(departure: Departure) {
    this.stopDeparturesComponent.showVehicle(departure.vehicle);
  }

  private appendDepartures(newDepartures: Departure[]) {
    newDepartures.forEach(depart => {
      this.updateDisabilityInfo(depart);
      if (this.isAlreadyAdded(depart)) {
        return;
      }
      this.departures.push(depart);
    });
    this.departures = this.departures.sort(DeparturesComponent.compareDepartures);
  }

  private isAlreadyAdded(depart: Departure) {
    return this.departures.find(d2 => d2.vehicle.id === depart.vehicle.id);
  }

  private updateDisabilityInfo(departure: Departure): void {
    this.vehicleService.findAllVehicleModels().then(data => {
      const vehicle = data[departure.vehicle.id];
      if (vehicle) {
        departure.forDisabled = vehicle.low;
      }
    });
  }

  private completeLoad(): void {
    this.isLoading = false;
    this.lastUpdated = new Date();
  }
}
