import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FavouriteDeparturesComponent} from './departures/favourite/favourite-departures.component';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SettingsComponent} from './settings/settings.component';
import {AddRouteComponent} from './settings/add-stop/add-route.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {DeparturesComponent} from './departures/departures.component';
import {NearestDeparturesComponent} from './departures/nearest/nearest-departures.component';
import {InlineSVGModule} from 'ng-inline-svg';
import {StopDeparturesComponent} from './stop/departures/stop-departures.component';
import {MapComponent} from './map/map.component';
import {DirectionComponent} from './departures/direction/direction.component';
import {AlertsComponent} from './alerts/alerts.component';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TrainStationComponent} from './departures/train-station/train-station.component';
import {TrainTripComponent} from './departures/train-trip/train-trip.component';
import {DirectionMapComponent} from './departures/direction/direction-map/direction-map.component';
import {DirectionStepsComponent} from './departures/direction/direction-steps/direction-steps.component';
import {NotFoundComponent} from './departures/not-found/not-found.component';
import {StopPinComponent} from './stop/pin/stop-pin.component';


@NgModule({
  declarations: [
    AppComponent,
    FavouriteDeparturesComponent,
    SettingsComponent,
    AddRouteComponent,
    DeparturesComponent,
    NearestDeparturesComponent,
    StopDeparturesComponent,
    StopPinComponent,
    MapComponent,
    DirectionComponent,
    AlertsComponent,
    TrainStationComponent,
    TrainTripComponent,
    DirectionMapComponent,
    DirectionStepsComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    TimepickerModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
