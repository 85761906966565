import {Actual, Vehicle} from '../model';
import {StopPoint} from '../client/stop-point-client-service';
import {Pattern} from '../client/Pattern';

export class Departure {
  public forDisabled: ForDisabled;

  constructor(public line: string,
              public stop: string,
              public stopPoint: StopPoint,
              public destination: string,
              public minutesToDepart: number,
              public actualTime: string,
              public plannedTime: string,
              public vehicle: Vehicle) {
  }

  public static create(stopPoint: StopPoint, actual: Actual) {
    return new Departure(actual.patternText,
      stopPoint.getStopName(),
      stopPoint,
      actual.direction,
      Number(actual.mixedTime.split(' ')[0]),
      actual.actualTime,
      actual.plannedTime,
      actual.getVehicle());
  }

  getAvailableTime(): string {
    if (this.actualTime) {
      return this.actualTime;
    }
    return this.plannedTime;
  }

  matchesPatterns(patterns: Pattern[]) {
    return patterns.some(r => this.matchesPattern(r));
  }

  private matchesPattern(pattern: Pattern): boolean {
    return pattern.line === this.line &&
      pattern.destination === this.destination;
  }
}

export enum ForDisabled {
  NO,
  HALF,
  YES
}
