import {Component, Input} from '@angular/core';
import {Leg} from '../../../client/direction.service';
import {Place} from '../../../client/places.service';
import {DirectionMode} from '../directionMode';

@Component({
  selector: 'app-direction-steps',
  templateUrl: './direction-steps.component.html',
  styleUrls: ['./direction-steps.component.less']
})
export class DirectionStepsComponent {

  @Input()
  leg: Leg;
  isLoading: boolean;
  @Input()
  place: Place;
  @Input()
  mode: DirectionMode;
  DirectionMode = DirectionMode;
}
