import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VehicleModel} from './vehicle-model';
import {convertTo} from '../utils';
import {URL_CORS} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  vehicleModels: Promise<{ string: typeof VehicleModel }>;

  constructor(private http: HttpClient) {
    this.vehicleModels = this.http.get<{ string: VehicleModel }>(`${URL_CORS}/vehicles/all`)
      .toPromise()
      .then(res => {
        const vehicleModels = {string: VehicleModel};
        Object.keys(res).forEach(id => {
          vehicleModels[id.substr(1, id.length - 1)] = convertTo(VehicleModel)(res[id]);
        });
        return vehicleModels;
      });
  }

  findAllVehicleModels(): Promise<{ string: typeof VehicleModel }> {
    return this.vehicleModels;
  }
}
