import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Vehicle} from '../model';
import {TimeCalculator} from './time-calculator';
import {URL_CORS} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TripInfoService {

  constructor(private http: HttpClient) {
  }

  getTripInfo(vehicle: Vehicle): Observable<TripDeparture[]> {
    return this.http.get<any>
    (`${URL_CORS}/trip-info/${vehicle.category}?tripId=${vehicle.tripId}&mode=departure`)
      .pipe(map(trip => trip.old.concat(trip.actual)))
      .pipe(map(departures => departures.map(d => new TripDeparture(d))));
  }
}

export class TripDeparture {
  private stop: any;
  private actualTime: string;
  status: string;
  timeToArrive: string;

  getStopName(): string {
    return this.stop.name;
  }

  getTime(): string {
    return this.actualTime;
  }

  constructor(obj?: any) {
    Object.assign(this, obj);
    if (this.status === 'PREDICTED') {
      this.timeToArrive = TimeCalculator.timeFromNow(this.getFullTime());
    }
  }

  private getFullTime(): Date {
    const date = new Date();
    date.setHours(Number(this.actualTime.split(':')[0]));
    date.setMinutes(Number(this.actualTime.split(':')[1]));
    return date;
  }
}



