<div class="container">
  <div class="row">
    <div class="col-12">
      <label for="from_stop">Przystanek początkowy:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input [(ngModel)]="stopFromGroup" [inputFormatter]="formatter" [ngbTypeahead]="search"
             [resultFormatter]="formatter"
             class="form-control"
             id="from_stop" type="text"/>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <label for="to-stop">Przystanek końcowy:</label>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input [(ngModel)]="stopToGroup" [inputFormatter]="formatter" [ngbTypeahead]="search"
             [resultFormatter]="formatter"
             class="form-control"
             id="to-stop" type="text"/>
    </div>
  </div>
  <div class="row pt-5">
    <div class="col-12 text-center">
      <a (click)="addRoute()" *ngIf="stopFromGroup && stopToGroup"
         class="mr-5">
        <img src="assets/icons/save.svg"/>
      </a>
      <a routerLink="..">
        <img src="assets/icons/cancel.svg"/>
      </a>
    </div>
  </div>
</div>

