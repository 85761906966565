import {Injectable} from '@angular/core';
import {Actual, DeparturesFromStop, StopCategory} from '../model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StopPoint} from './stop-point-client-service';
import {tap} from 'rxjs/operators';
import {VehicleService} from '../vehicle/vehicle.service';
import {Stop} from './stop-client-service';
import {URL_CORS} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DeparturesClientService {

  // @ts-ignore
  private appendVehicles = tap(departures => departures.actual.forEach(actual => {
    this.vehicleService.findAllVehicleModels().then(models => {
      const vehicleModel = models[actual.getVehicle().id];
      if (vehicleModel) {
        actual.low = vehicleModel.low;
      }
    });
  }));

  constructor(private http: HttpClient,
              private vehicleService: VehicleService) {
  }

  getDeparturesFromStopPoint(stopPoint: StopPoint): Observable<DeparturesFromStop> {
    // @ts-ignore
    return this.http.get<DeparturesFromStop>
    (`${URL_CORS}/departures-from-stop-point/${stopPoint.category}`
      + `?stopPoint=${stopPoint.stopPoint}&mode=departure`)
      .pipe(this.toActuals(stopPoint.category))
      .pipe(this.appendVehicles);
  }

  getDeparturesFromStop(stop: Stop): Observable<DeparturesFromStop> {
    // @ts-ignore
    return this.http.get<DeparturesFromStop>
    (`${URL_CORS}/departures-from-stop/${stop.category}?stop=${stop.shortName}`)
      .pipe(this.toActuals(stop.category))
      .pipe(this.appendVehicles);
  }

  private toActuals(category: StopCategory) {
    return tap(departures => {
      // @ts-ignore
      departures.actual = departures.actual
        .map(actual => new Actual(category, actual));
    });
  }
}
