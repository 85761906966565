<ng-template #train_station_content let-modal>
  <div [ngClass]="{ 'modal-header' : true}" class="modal-header mb-0 pb-1">
    <button (click)="modal.dismiss();" aria-label="Close"
            class="close align-text-left ml-0 pl-0"
            type="button">
    </button>
    <h6 class="modal-title text-center" id="modal-basic-title">Odjazdy ze stacji</h6>
    <button (click)="modal.dismiss()" aria-label="Close"
            class="close ml-0" type="button">
      <img class="close-icon" src="assets/icons/cancel.svg"/>
    </button>
  </div>
  <div class="modal-body pl-0">
    <div class="container">
      <div *ngIf="isUpdateInProcess">
        Trwa aktualizacja rozkładu...
        <img class="w-100" src="assets/icons/loading.gif"/>
      </div>
      <div class="row">
        <div class="col-2">
          <img src="assets/icons/heavy_rail.svg"/>
        </div>
        <div class="col-10 font-weight-bold">
          {{trainStation.name}}
        </div>
      </div>
      <div *ngFor="let departure of departures"
           (click)="showLegs(departure)" class="row pt-1 pb-1 mt-1">
        <div class="col-2">
          {{departure.departureDateTime.substr(11, 5)}}
        </div>
        <div class="col-6 pl-0">
          {{departure.line}}->{{departure.headsign}}
        </div>
        <div class="col-2 pr-0 pl-0">
          {{departure.platform}}
        </div>
        <div class="col-1 pl-0 ml-0">
          <img alt="" height="18em" src="assets/icons/{{departure.transportType.toLowerCase()}}.jpg">
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-train-trip></app-train-trip>


