<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" name="viewport">

  <link crossorigin="anonymous" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" rel="stylesheet">

  <link crossorigin="anonymous" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" rel="stylesheet">

  <title>Najbliższe odjazdy</title>
</head>
<body>
<div class="text-right p-1 mb-2 mt-1">
  <span class="p-2">
  <a [inlineSVG]="'assets/icons/favourite.svg'" [routerLinkActiveOptions]="{exact: true}" routerLink=""
     routerLinkActive="active">
  </a>
</span>
  <span class="p-2">
  <a [inlineSVG]="'assets/icons/direction.svg'" [routerLinkActiveOptions]="{exact: true}" routerLink="direction"
     routerLinkActive="active">
  </a>
</span>
  <span class="p-2">
  <a [inlineSVG]="'assets/icons/map.svg'" routerLink="map" routerLinkActive="active"></a>
</span>
  <span class="p-2">
  <a [inlineSVG]="'assets/icons/nearest.svg'" routerLink="nearest" routerLinkActive="active"></a>
</span>
  <span class="p-2">
  <a [inlineSVG]="'assets/icons/alerts.svg'" routerLink="alerts" routerLinkActive="active"></a>
</span>
  <span class="p-2">
  <a [inlineSVG]="'assets/icons/settings.svg'" routerLink="settings" routerLinkActive="active"></a>
</span>
</div>
<router-outlet></router-outlet>

<script crossorigin="anonymous"
        integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n"
        src="https://code.jquery.com/jquery-3.4.1.slim.min.js"></script>
<script crossorigin="anonymous"
        integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
        src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"></script>
<script crossorigin="anonymous"
        integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6"
        src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js"></script>
</body>
</html>
