import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {Feature} from 'ol';
import {HttpClient} from '@angular/common/http';
import {Subscription, timer} from 'rxjs';
import {tap} from 'rxjs/operators';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import {Geometry, LineString, Point} from 'ol/geom';
import {Fill, Icon, Stroke, Style, Text} from 'ol/style';
import {Select} from 'ol/interaction';
import {StopDeparturesComponent} from '../stop/departures/stop-departures.component';
import {StopCategory, Vehicle} from '../model';
import {PathClientService} from '../client/path-client.service';
import {DeparturesMap, MapProvider} from './map-provider';
import {convertTo} from '../utils';
import {TRAIN_STATION_KEY, TrainStationComponent} from '../departures/train-station/train-station.component';
import {STOP_POINT_KEY, StopPointMapDisplay} from './stop-point-map-display';
import {URL_CORS} from '../../environments/environment';

const REFRESH_PERIOD = 5_000;
const PI_OVER_180 = 0.0174533;
const VEHICLE_KEY = 'vehicle';
const PATH_FEATURE_ID = 'path';

const animationIntervals = [];


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less']
})
export class MapComponent implements AfterViewInit, OnDestroy {

  @ViewChild(StopDeparturesComponent) stopDeparturesComponent: StopDeparturesComponent;
  @ViewChild(TrainStationComponent, {static: true}) trainStationComponent: TrainStationComponent;

  map: DeparturesMap;
  private stopPointSource = new VectorSource();
  private stopPointLayer = new VectorLayer({source: this.stopPointSource});
  private trainStationSource = new VectorSource();
  private trainStationLayers = new VectorLayer({source: this.trainStationSource});
  private select = new Select({style: null});

  constructor(private mapProvider: MapProvider,
              private pathClientService: PathClientService,
              private stopPointDisplay: StopPointMapDisplay,
              private httpClient: HttpClient) {
  }

  positionsSubscription: Subscription;

  ngAfterViewInit(): void {
    this.map = this.mapProvider.provideMap();
    this.map.getLayers().insertAt(1, this.stopPointLayer);
    this.map.getLayers().insertAt(2, this.trainStationLayers);
    this.clearAnimationIntervals();
    this.subscribePositions();
    this.map.on('moveend', () => {
      if (this.map.getView().getZoom() < 14) {
        this.stopPointLayer.setVisible(false);
      } else {
        this.stopPointLayer.setVisible(true);
      }
    });
    this.updateMap();
    this.stopPointDisplay.showStopPoints(this.stopPointSource);
    this.trainStationComponent.addTrainStationPins(feature => this.trainStationSource.addFeature(feature));
    this.allowShowingPopUps();
  }

  ngOnDestroy(): void {
    this.positionsSubscription.unsubscribe();
  }

  private subscribePositions() {
    this.positionsSubscription =
      timer(REFRESH_PERIOD, REFRESH_PERIOD)
        .pipe(tap(() => this.updateMap()))
        .subscribe();
  }

  private allowShowingPopUps() {
    this.select.getFeatures().on('add', e => {
      this.select.getFeatures().clear();
      const feature = e.element;
      const stopPoint = feature.get(STOP_POINT_KEY);
      this.clearPath();
      if (stopPoint) {
        this.stopDeparturesComponent.showStopPoint(stopPoint);
        return;
      }
      const trainStation = feature.get(TRAIN_STATION_KEY);
      if (trainStation) {
        this.trainStationComponent.show(trainStation);
        return;
      }
      const vehicle = feature.get(VEHICLE_KEY);
      this.stopDeparturesComponent.showVehicle(vehicle);
      this.showPath(vehicle);
      this.positionsSubscription.unsubscribe();
      this.updateMap();
      this.subscribePositions();
    });
    this.map.addInteraction(this.select);
  }

  private showPath(vehicle: Vehicle) {
    this.pathClientService.findPath(vehicle)
      .subscribe(wayPoints => {
        const path = new Feature({
          geometry: new LineString(wayPoints),
        });
        path.setId(PATH_FEATURE_ID);
        path.setStyle(new Style({
          stroke: new Stroke({color: vehicle.getColorPath(), width: 5})
        }));
        this.map.addFeature(path);
      });
  }

  private clearPath() {
    const feature = this.map.getFeatureById(PATH_FEATURE_ID);
    if (feature) {
      this.map.removeFeature(feature);
    }
  }

  private updateMap() {
    this.map.updateCurrentLocation();
    this.clearAnimationIntervals();
    this.showVehicles(StopCategory.BUS, 'yellow');
    this.showVehicles(StopCategory.TRAM, 'black');
  }

  private clearAnimationIntervals() {
    animationIntervals.forEach(intervalId => {
      clearInterval(intervalId);
    });
    animationIntervals.length = 0;
  }

  private showVehicles(category: StopCategory, labelColor: string) {
    this.httpClient.get<any>(`${URL_CORS}/vehicles-positions/${category}`)
      .subscribe(data => {
        data.vehicles
          .map(convertTo(Vehicle))
          .forEach(v => {
            if (v.isDeleted) {
              const featureById = this.map.getFeatureById(v.id);
              if (featureById) {
                featureById.setStyle(new Style({}));
              }
              return;
            }
            v.category = category;
            this.addVehiclePin(v, labelColor);
          });
      });
  }

  private addVehiclePin(vehicle: Vehicle, labelColor: string) {
    const iconStyle = new Style({
      text: new Text({
        text: vehicle.getLine(),
        font: 'bold 12pt Arial, Verdana, Helvetica, sans-serif',
        rotateWithView: true,
        rotation: vehicle.getLabelRotation() * PI_OVER_180,
        fill: new Fill({color: labelColor}),
      }),
      image: new Icon({
        rotateWithView: true,
        rotation: (vehicle.heading - 90) * PI_OVER_180,
        src: vehicle.getIcon(),
        scale: 0.16
      }),
    });
    const existingFeature = this.map.getFeatureById(vehicle.id);
    if (existingFeature) {
      existingFeature.setStyle(iconStyle);
      this.moveVehicle(existingFeature, vehicle.getCoords());
      return;
    }
    const newFeature = this.map.addNewFeature(vehicle.getCoords(), iconStyle);
    newFeature.set('coords', vehicle.getCoords());
    newFeature.setId(vehicle.id);
    newFeature.set(VEHICLE_KEY, vehicle);
  }

  private moveVehicle(vehicleFeature: Feature<Geometry>, coords: number[]) {
    const line = new LineString([vehicleFeature.get('coords'), coords]);
    let step = 0;
    vehicleFeature.set('coords', coords);
    const key = setInterval(() => {
      if (step < 100) {
        step++;
        vehicleFeature.setGeometry(new Point(line.getCoordinateAt(step / 100)));
      } else {
        clearInterval(key);
      }
    }, 5);
    animationIntervals.push(key);
  }
}

