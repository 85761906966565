import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractStop, PATTERNS_URL, Stop} from './stop-client-service';
import {StopCategory} from '../model';
import {Icon, Style} from 'ol/style';
import {convertTo} from '../utils';
import {URL_CORS} from '../../environments/environment';


const STORAGE_KEY = 'STOPS_POINTS';

export const LAST_UPDATE_STOP_POINTS_KEY = 'STOP_POINTS_LAST_UPDATED';

@Injectable({
  providedIn: 'root'
})
export class StopPointClientService {

  constructor(private http: HttpClient,
              @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  preHeatCache(): void {
    this.http.get<any>(PATTERNS_URL)
      .pipe(map(resp => resp.updated))
      .subscribe(patternsLastModified => {
        if (this.isUpToDate(patternsLastModified)) {
          return;
        }
        forkJoin([this.getStopPointsObservable(StopCategory.BUS), this.getStopPointsObservable(StopCategory.TRAM)])
          .pipe(map(([busStopPoints, tramStopPoints]) => busStopPoints.concat(tramStopPoints)),
            map(stops => stops.filter(s => s.category !== 'other')))
          .subscribe(res => {
            this.storage.set(STORAGE_KEY, res);
            this.storage.set(LAST_UPDATE_STOP_POINTS_KEY, patternsLastModified);
          });
      });
  }

  private isUpToDate(patternsLastModified: string): boolean {
    return this.storage.get(STORAGE_KEY) && patternsLastModified === this.storage.get(LAST_UPDATE_STOP_POINTS_KEY);
  }

  getAllStopPoints(): StopPoint[] {
    const stopPoints = this.storage.get(STORAGE_KEY);
    if (!stopPoints) {
      return [];
    }
    return stopPoints
      .map(convertTo(StopPoint));
  }

  findStopPointsByStop(stop: Stop): StopPoint[] {
    return this.getAllStopPoints()
      .filter(stopPoint => stopPoint.shortName === stop.shortName)
      .filter(stopPoint => stopPoint.category === stop.category);
  }

  private getStopPointsObservable(category: StopCategory) {
    return this.http.get<any>(`${URL_CORS}/stop-points/${category}`)
      .pipe(map(res => res.stopPoints));
  }
}


export class StopPoint extends AbstractStop {
  stopPoint: string;

  getCoordinates(): number[] {
    return [this.longitude / 3600000, this.latitude / 3600000];
  }

  getIconStyle() {
    return new Style({
      image: new Icon({
        src: `assets/icons/${this.category.toLowerCase()}_stop.png`,
        scale: 0.08
      }),
    });
  }

  getStopName() {
    return this.name
      .split(' ')
      .slice(0, -1)
      .join(' ');
  }
}

