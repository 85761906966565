<div class="container nearest">
  <div class="row font-weight-bold  pt-2 pb-2 text-center">
    <div class="col-12">
      <label for="slider">Pokazuj w promieniu:</label> <span style="color: #080773">{{range}} m</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <input (change)="scheduleLoading()" [(ngModel)]="range" class="custom-range" id="slider" max="1000" min="0"
             step="1"
             type="range">
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="form-group has-search">
        <span class="fa fa-search form-control-feedback"></span>
        <input #filter (input)="departuresComponent.applyFilter(filter.value)" class="form-control" id="search"
               autocomplete="off" placeholder="Szukaj..."
               type="search" value="">
      </div>
    </div>
  </div>
</div>
<app-departures></app-departures>
