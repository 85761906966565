import {Component, OnInit, ViewChild} from '@angular/core';
import {DepartureService} from '../../client/departure.service';
import {mergeMap} from 'rxjs/operators';
import {StopClientService} from '../../client/stop-client-service';
import {DeparturesComponent} from '../departures.component';


@Component({
  selector: 'app-nearest-departures',
  templateUrl: './nearest-departures.component.html',
  styleUrls: ['./nearest-departures.component.less']
})
export class NearestDeparturesComponent implements OnInit {

  @ViewChild(DeparturesComponent, {static: true}) departuresComponent: DeparturesComponent;

  range = 100;

  constructor(private stopClientService: StopClientService,
              private departureService: DepartureService) {
  }

  ngOnInit(): void {
    this.scheduleLoading();
  }

  scheduleLoading() {
    const observableDepartures = this.stopClientService
      .findAllStopsInRange(this.range / 1000)
      .pipe(mergeMap(stops => this.departureService.loadDeparturesFromStops(stops)));
    this.departuresComponent.scheduleLoading(observableDepartures);
  }
}
