<div *ngIf="leg" class="row mt-2">
  <div *ngIf="leg.departureTime" class="col-12 text-center">
    <b>{{leg.departureTime}} - {{leg.arrivalTime}}</b> ({{leg.duration}})
  </div>
  <div *ngIf="!leg.departureTime" class="col-12 text-center">
    <b>{{leg.duration}}</b>
  </div>
</div>
<ng-container *ngFor="let step of ( leg ? leg.steps: []); last as isLast; first as isFirst">
  <ng-container *ngIf="step.isTransit">
    <div class="row direction">
      <div class="col-1">
        <img class="point" src="assets/icons/{{step.stopIcon}}">
      </div>
      <div class="col-8 font-weight-bold">
        {{step.departureName}}
      </div>
      <div class="col-3 font-weight-bold">
        {{step.departureTime}}
      </div>
    </div>
    <div class="row direction mb-3 mt-3">
      <div class="col-1">
      </div>
      <div class="col-1">
        <img src="assets/icons/{{step.vehicleIcon}}"/>
      </div>
      <div class="col-7 font-weight-bold">
        {{step.line}} -> {{step.headSign}}
      </div>
      <div class="col-3">
        {{step.duration}}
      </div>
    </div>
    <div class="row direction">
      <div class="col-1">
        <img class="point" src="assets/icons/{{step.stopIcon}}">
      </div>
      <div class="col-8">
        {{step.arrivalName}}
      </div>
      <div class="col-3">
        {{step.arrivalTime}}
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="!step.isTransit">
    <div *ngIf="isFirst" class="row direction mt-2">
      <div class="col-1">
        <img class="location" src="assets/icons/nearest.svg">
      </div>
      <div class="col-8">
        {{leg.departureName}}
      </div>
      <div class="col-3">
        {{leg.departureTime}}
      </div>
    </div>
    <div *ngIf="mode === DirectionMode.TRANSIT" class="row direction mb-3 mt-3">
      <div class="col-1">
      </div>
      <div class="col-1">
        <img src="assets/icons/{{step.vehicleIcon}}"/>
      </div>
      <div class="col-3">
        {{step.duration}}
      </div>
    </div>
    <div *ngIf="mode !== DirectionMode.TRANSIT && isLast" class="row direction mb-3 mt-3">
      <div class="col-1">
      </div>
      <div class="col-1">
        <img src="assets/icons/{{step.vehicleIcon}}"/>
      </div>
      <div class="col-3">
        {{leg.duration}}
      </div>
    </div>
    <div *ngIf="isLast" class="row direction">
      <div class="col-1">
        <img class="point" src="assets/icons/stop_black.svg">
      </div>
      <div class="col-8">
        {{place.getDescription()}}
      </div>
      <div class="col-3">
        {{leg.arrivalTime}}
      </div>
    </div>
  </ng-container>
</ng-container>
