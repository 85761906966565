import {fromLonLat} from 'ol/proj';
import {convertTo} from './utils';

export enum StopCategory {
  BUS = 'bus',
  TRAM = 'tram'
}

export class Vehicle {
  longitude: number;
  latitude: number;
  name: string;
  heading: number;
  tripId: string;
  isDeleted: boolean;
  id: string;
  category: StopCategory;

  getIcon(): string {
    if (this.heading >= 180) {
      return `assets/icons/${this.category}_map_rotate.png`;
    }
    return `assets/icons/${this.category}_map.png`;
  }

  getLine(): string {
    return this.name.split(' ')[0];
  }

  getDirection(): string {
    return this.name.replace(' ', ' -> ');
  }

  getLabelRotation(): number {
    if (this.heading >= 180) {
      return this.heading + 90;
    }
    return this.heading - 90;
  }

  getCoords(): number[] {
    return fromLonLat([this.longitude / 3600000.0, this.latitude / 3600000.0]);
  }

  getColorPath() {
    if (this.category === StopCategory.TRAM) {
      return 'green';
    }
    return 'red';
  }
}

export class Actual {
  actualRelativeTime: number;
  actualTime: string;
  direction: string;
  mixedTime: string;
  passageid: string;
  patternText: string;
  plannedTime: string;
  routeId: string;
  status: string;
  tripId: string;
  vehicleId: string;
  low: number;
  category: StopCategory;

  constructor(category: StopCategory, obj?: any) {
    Object.assign(this, obj);
    this.category = category;
  }

  getVehicle(): Vehicle {
    return convertTo(Vehicle)({
      id: this.vehicleId,
      tripId: this.tripId,
      category: this.category,
      name: `${this.patternText} ${this.direction}`
    });
  }
}

export interface DeparturesFromStop {
  actual: Actual[];
  routes: Route[];
  stopName: string;
  stopShortName: string;
}

export const FAVOURITE_ROUTES_KEY = 'FAVOURITE_ROUTES';

export interface Route {
  alerts: any[];
  authority: string;
  directions: string[];
  id: string;
  name: string;
  routeType: string;
  shortName: string;
}



