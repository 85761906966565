import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Icon, Style} from 'ol/style';
import {convertTo} from '../utils';
import {BehaviorSubject, Subject} from 'rxjs';
import {TimeCalculator} from './time-calculator';
import Dexie from 'dexie';

const STATIONS_STORAGE_KEY = 'TRAIN_STATIONS';
const TRIPS_STORAGE_KEY = 'TRAIN_TRIPS';

const ETAG_KEY = 'TRAIN_STATIONS_ETAG';

export const STORAGE_URL = 'https://storage.googleapis.com/storage/v1/b/najblizsze-odjazdy/o/';
const TRAIN_STATIONS_URL = STORAGE_URL + `train-departures.json?alt=media&reload${Math.random().toString(36)}`;
const TRAIN_TRIPS_URL = STORAGE_URL + `train-trips.json?alt=media&reload=${Math.random().toString(36)}`;

const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;


@Injectable({
  providedIn: 'root'
})
export class TrainStationsClientService {
  private subject = new BehaviorSubject<TrainStation[]>([]);
  db = new Dexie('train');
  isUpdateInProcess = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient,
              @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.db.version(1)
      .stores({
        TRAIN_STATIONS: '++id',
        TRAIN_TRIPS: '++id,tripId,departures'
      });
  }

  preHeatCache(): void {
    this.http.head<any>(TRAIN_STATIONS_URL, {
      observe: 'response'
    })
      .subscribe(resp => {
        const etag = resp.headers.get('ETag');
        if (this.isNotUpToDate(etag)) {
          this.isUpdateInProcess.next(true);
          this.updateData(etag);
        }
      });
  }

  getAll(): Subject<TrainStation[]> {
    this.reloadSubject();
    return this.subject;
  }

  async getTrainStationById(stationId: string): Promise<TrainStation> {
    const station = await this.db.table(STATIONS_STORAGE_KEY)
      .where('id').equals(stationId)
      .first();
    return convertTo(TrainStation)(station);
  }

  async getLegsForDeparture(departure: TrainDeparture): Promise<Leg[]> {
    const date = departure.departureDateTime.substr(0, 10);
    return this.db.table(TRIPS_STORAGE_KEY)
      .where('tripId').equals(departure.tripId)
      .first()
      .then(s => {
        return s.legs
          .map(leg => new Leg(leg))
          .filter(leg => leg.date === date);
      });
  }

  private updateData(lastModified: string) {
    this.http.get<any[]>(TRAIN_TRIPS_URL)
      .subscribe(resp => {
        this.db.table(TRIPS_STORAGE_KEY).clear();
        this.db.table(TRIPS_STORAGE_KEY).bulkAdd(resp);
      });
    this.http.get<any[]>(TRAIN_STATIONS_URL)
      .subscribe(resp => {
        this.db.table(STATIONS_STORAGE_KEY).clear();
        this.db.table(STATIONS_STORAGE_KEY).bulkAdd(resp);
        this.reloadSubject();
        this.storage.set(ETAG_KEY, lastModified);
        this.isUpdateInProcess.next(false);
      });
  }

  private reloadSubject() {
    this.db.table(STATIONS_STORAGE_KEY)
      .toArray()
      .then(stations => {
        if (stations) {
          this.subject.next(stations.map(convertTo(TrainStation)));
        }
      });
  }

  private isNotUpToDate(lastModified: string): boolean {
    const lastUpdate = this.storage.get(ETAG_KEY);
    if (!lastUpdate) {
      return true;
    }
    return lastModified !== lastUpdate;
  }
}

export class TrainStation {
  id: string;
  name: string;
  latitude: number;
  longitude: number;
  departures: TrainDeparture[];

  getCoordinates(): number[] {
    return [this.longitude, this.latitude];
  }

  getNextDepartures(): TrainDeparture[] {
    const currentTimestamp = new Date().valueOf();
    return this.departures
      .filter(d => Date.parse(d.departureDateTime) >= currentTimestamp)
      .filter(d => Date.parse(d.departureDateTime) <= currentTimestamp + MILLISECONDS_IN_DAY)
      .slice(0, 15);
  }

  getIconStyle() {
    return new Style({
      image: new Icon({
        src: `assets/icons/train_stop.png`,
        scale: 0.08
      }),
    });
  }
}

export class TrainDeparture {
  departureDateTime: string;
  headsign: string;
  platform;
  line: string;
  tripId: string;
  transportType: string;
}

export class Leg {
  isInFuture: boolean;
  time: string;
  date: string;
  stopName: string;
  timeToArrive: string;

  constructor(obj?: any) {
    Object.assign(this, obj);
    this.time = obj.dateTime.substr(11, 5);
    this.date = obj.dateTime.substr(0, 10);
    const dateTime = Date.parse(obj.dateTime);
    this.isInFuture = Date.now().valueOf() < dateTime;
    this.timeToArrive = TimeCalculator.timeFromNow(dateTime);
  }
}

