import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Pattern} from './Pattern';
import {Stop} from './stop-client-service';
import {FAVOURITE_ROUTES_KEY} from '../model';


// tslint:disable-next-line:max-line-length
const ROUTES_URL = `https://storage.googleapis.com/storage/v1/b/najblizsze-odjazdy/o/routes.json?alt=media&reload=${Math.random().toString(36)}`;
const ROUTES_KEY = 'ROUTES';


@Injectable({
  providedIn: 'root'
})
export class RouteClientService {

  constructor(private http: HttpClient,
              @Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  update(): void {
    this.http.get<any[]>(ROUTES_URL)
      .subscribe(res => {
        this.storage.set(ROUTES_KEY, res);
        this.recomputePatterns();
      });
  }

  getPatternsOfRoute(fromStops: Stop[], toStops: Stop[]): Pattern[] {
    const start = fromStops[0].name;
    const stop = toStops[0].name;

    return this.storage.get(ROUTES_KEY)
      .filter(r => r.stops.includes(start))
      .filter(r => r.stops.includes(stop))
      .filter(r => r.stops.indexOf(start) < r.stops.indexOf(stop))
      .map(r => new Pattern(r.line, r.destination));
  }

  private recomputePatterns(): void {
    const routes = this.storage.get(FAVOURITE_ROUTES_KEY);
    routes.forEach(route => {
      route.patterns = this.getPatternsOfRoute(route.fromStops, route.toStops);
    });
    this.storage.set(FAVOURITE_ROUTES_KEY, routes);
  }
}
